import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
import * as styles from "../components/saas-files/newsPost.module.scss"
import twitter from "../images/react-icons/twitter white logo.svg"
import MainLayout from "../layouts/MainLayout"
import "../components/event-details-page/EventDetails.scss"

const BlogPostTemplate = ({ pageContext }) => {
  const {
    title,
    seo,
    readingTime,
    featuredImage,
    rawPost,
    publishedOn,
    slug,
    cta,
  } = pageContext?.data
  // const banner = newsVerticalBanner?.banner
  const featureImage = getImage(featuredImage?.localFile)
  const url = `https://invozone.com/technews/${slug}/`

  return (
    <MainLayout>
      <section className={styles.hero}>
        <img
          decoding="async"
          loading="lazy"
          className={styles.newsImg}
          src={featureImage?.images?.fallback?.src || ""}
          alt="news"
        />
        <div className="d-flex h-100">
          <Container>
            <div className={styles.heroContent}>
              <Row className="justify-content-center">
                <Col xs={12} md={10} lg={8}>
                  <h1 className={styles?.heading}>{title}</h1>
                </Col>
                <Col xs={12} md={10} lg={8}>
                  <div className="mt-5">
                    {publishedOn} &#124; {readingTime}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <div className={styles.overlay} />
        </div>
      </section>
      <section className={styles.content}>
        <Container>
          <Row className="justify-content-md-center">
            <div className="Socila-share">
              <FacebookShareButton url={url} quote={seo.metaDesc} hashtag={[]}>
                <FacebookIcon size={32} borderRadius={5} />
              </FacebookShareButton>

              <LinkedinShareButton
                url={url}
                title={seo.title}
                summary={seo.metaDesc}
                source=""
              >
                <LinkedinIcon size={32} borderRadius={5} />
              </LinkedinShareButton>

              <TwitterShareButton
                url={url}
                title={seo.title}
                via=""
                hashtags={[]}
              >
                <div
                  style={{
                    height: "32px",
                    width: "32px",
                    justifyContent: "center",
                    display: "flex",
                    background: "#1e9cf0",
                    borderRadius: "3px",
                    margin: "auto",
                  }}
                >
                  <img
                    src={twitter}
                    borderRadius={5}
                    decoding="async"
                    loading="lazy"
                    alt="twitter"
                    style={{ width: "16px" }}
                  />
                </div>
              </TwitterShareButton>
              <RedditShareButton url={url} title={seo.title}>
                <RedditIcon size={32} borderRadius={5} />
              </RedditShareButton>
              <EmailShareButton
                url={url}
                subject={seo.title}
                body={seo.metaDesc}
              >
                <EmailIcon size={32} borderRadius={5} />
              </EmailShareButton>
              <WhatsappShareButton url={url} title={seo.title}>
                <WhatsappIcon size={32} borderRadius={5} />
              </WhatsappShareButton>
            </div>
            <Col
              xs={12}
              lg={cta && cta?.url ? 8 : 12}
              xl={cta && cta?.url ? 9 : 12}
              className={cta && cta?.url && `pr-md-5`}
            >
              {/* <Row> */}
              <div dangerouslySetInnerHTML={{ __html: rawPost }} />
              {/* </Row> */}
            </Col>
            {cta && cta?.url && (
              <Col
                xs={12}
                lg={4}
                xl={3}
                classNam="justify-content-center d-flex mb-3"
              >
                {/* {cta?.map(item => ( */}
                <Link to={`/${cta?.url}/`}>
                  <GatsbyImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    image={getImage(cta?.image?.localFile)}
                  />
                </Link>
                {/* ))} */}
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <section className={styles.mobileShareButtons}>
        <Container>
          <Row className="justify-content-md-center">
            <Col md="6">
              <Row className="justify-content-center">
                <div className="Socila-share-mobile">
                  <FacebookShareButton
                    url={url}
                    quote={seo.metaDesc}
                    hashtag={[]}
                  >
                    <FacebookIcon size={32} borderRadius={5} />
                  </FacebookShareButton>

                  <LinkedinShareButton
                    url={url}
                    title={seo.title}
                    summary={seo.metaDesc}
                    source=""
                  >
                    <LinkedinIcon size={32} borderRadius={5} />
                  </LinkedinShareButton>

                  <TwitterShareButton
                    url={url}
                    title={seo.title}
                    via=""
                    hashtags={[]}
                  >
                    <div
                      style={{
                        height: "32px",
                        width: "32px",
                        justifyContent: "center",
                        display: "flex",
                        background: "#1e9cf0",
                        borderRadius: "3px",
                        margin: "auto",
                      }}
                    >
                      <img
                        src={twitter}
                        borderRadius={5}
                        decoding="async"
                        loading="lazy"
                        alt="twitter"
                        style={{ width: "16px" }}
                      />
                    </div>
                  </TwitterShareButton>
                  <RedditShareButton url={url} title={seo.title}>
                    <RedditIcon size={32} borderRadius={5} />
                  </RedditShareButton>
                  <EmailShareButton
                    url={url}
                    subject={seo.title}
                    body={seo.metaDesc}
                  >
                    <EmailIcon size={32} borderRadius={5} />
                  </EmailShareButton>
                  <WhatsappShareButton url={url} title={seo.title}>
                    <WhatsappIcon size={32} borderRadius={5} />
                  </WhatsappShareButton>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  )
}

export default BlogPostTemplate

export const Head = ({ pageContext }) => {
  const { seo } = pageContext?.data
  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.metaDesc}></meta>
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}
